import React from "react"
import ProductCard from "../components/ProductCard"
import Pagination from "../components/Pagination"

export default function Overview({ context, items, children }) {
  return (
    <section className="mt-4">
      <div className="container">
        {children}
        <div>
          <div className="columns is-multiline">
            {items.map((item, index) => {
              return (
                <div key={index} className="column is-one-quarter">
                  <ProductCard item={item.node} />
                </div>
              )
            })}
          </div>
        </div>

        {context.pageNumber !== undefined ? (
          <Pagination pagination={context} />
        ) : null}
      </div>
    </section>
  )
}
