import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default function ProductCard({ item }) {
  return (
    <div className="card">
      <div className="card-image">
        <figure>
          <Link to={item.fields.slug}>
            <Img
              alt={item.title}
              fluid={item.images[0].childImageSharp.fluid}
            ></Img>
          </Link>
        </figure>
      </div>

      <div className="card-header-title">
        <Link to={item.fields.slug}>{item.title}</Link>
      </div>
    </div>
  )
}
