import React from "react"
import { Link } from "gatsby"

export default function Pagination({ pagination }) {
  return (
    <div className="mt-4">
      <nav
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        <Link
          to={pagination.previousPagePath}
          className="pagination-previous"
          disabled={!pagination.previousPagePath}
        >
          Zur&uuml;ck
        </Link>
        <Link
          to={pagination.nextPagePath}
          className="pagination-next"
          disabled={!pagination.nextPagePath}
        >
          Weiter
        </Link>
      </nav>
    </div>
  )
}
